// app/PostHogPageView.tsx
"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { useSession } from "next-auth/react";

export default function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  const { data: session } = useSession();

  useEffect(() => {
    if (session?.user?.email && posthog) {
      posthog.identify(session.user.email);
      posthog.setPersonPropertiesForFlags({ user_id: session.user.id });
    }
  }, [session, posthog]);

  // Separate effect for tracking pageviews - runs on every pathname or searchParams change
  useEffect(() => {
    if (pathname && posthog) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url += `?${searchParams.toString()}`;
      }
      posthog.capture("$pageview", {
        $current_url: url,
      });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}
