"use client";

import WaitlistFormModal from "@/components/modal/waitlistFormModal";
import { Button, ButtonProps } from "@/components/ui/button";
import React, { useState } from "react";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { googleSignIn } from "@/lib/googleUtils";

type Props = ButtonProps & {
  callbackUrl?: string;
};

const GoogleAuthButton: React.FC<Props> = ({ callbackUrl, ...props }) => {
  const [showWaitlistFormModal, setShowWaitlistFormModal] = useState(false);

  const { isWebView } = useMainLayout();

  const onClick = () => {
    if (isWebView) {
      setShowWaitlistFormModal(true);
    } else {
      googleSignIn(callbackUrl);
    }
  };

  return (
    <>
      <WaitlistFormModal
        open={showWaitlistFormModal}
        onOpenChange={(val) => {
          if (!val) setShowWaitlistFormModal(false);
        }}
      />
      <Button onClick={onClick} {...props} />
    </>
  );
};

export default GoogleAuthButton;
