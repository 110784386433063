"use client";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { DialogProps } from "@radix-ui/react-dialog";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import useLandingPageInvite from "@/hooks/client/useLandingPageInvite";
import { isValidEmail } from "@/lib/utils";

const WaitlistFormModal: React.FC<DialogProps> = ({
  onOpenChange,
  ...props
}) => {
  const [emailAddress, setEmailAddress] = useState("");
  const { joinWaitlist, loading, submitted } = useLandingPageInvite();

  const isValid = isValidEmail(emailAddress);

  return (
    <Dialog onOpenChange={onOpenChange} {...props}>
      <DialogContent className="py-8">
        <DialogHeader>
          <DialogTitle className="text-center text-3xl">
            Join Gondola
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-4 py-1">
          {submitted ? (
            <p className="mt-2 text-center text-white">
              You&apos;re in! Check your inbox to confirm your email.
            </p>
          ) : (
            <>
              <Input
                type="email"
                placeholder="Email Address"
                className="h-[40px] w-full max-w-[400px] bg-input"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                disabled={submitted}
              />
              <Button
                onClick={() => joinWaitlist(emailAddress)}
                className=""
                loading={loading}
                disabled={submitted || !isValid}
              >
                Get Started
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WaitlistFormModal;
