"use client";

import { Input } from "@/components/ui/input";
import { InputProps } from "@/components/ui/input/Input";
import { cn } from "@/lib/utils";
import React, { HTMLAttributes, useRef, useImperativeHandle } from "react";

type Props = {
  label?: string;
  iconComponent?: React.ReactNode;
  iconSrc?: string;
  containerClassName?: HTMLAttributes<HTMLDivElement>["className"];
  labelClassName?: HTMLAttributes<HTMLSpanElement>["className"];
  suggestionsComponent?: React.ReactNode;
} & InputProps;

export type StyledInputProps = Props;

const StyledInput = React.forwardRef<HTMLInputElement, StyledInputProps>(
  (
    {
      label,
      containerClassName,
      iconSrc,
      suggestionsComponent,
      labelClassName,
      className,
      iconComponent,
      ...otherProps
    },
    ref
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLInputElement);

    return (
      <div
        className={cn(
          "flex flex-col items-start justify-center gap-2 rounded-[9px] bg-input px-6 py-4 text-primary",
          containerClassName
        )}
        onClick={() => {
          if (otherProps.type === "date") {
            innerRef?.current?.showPicker();
          } else {
            innerRef?.current?.focus();
          }
        }}
      >
        {label && (
          <span className={cn("text-[10px] text-[#898C90]", labelClassName)}>
            {label}
          </span>
        )}
        <div className="flex w-full flex-row items-center justify-center">
          {iconComponent ?? <img src={iconSrc} alt={label} />}
          <div className="relative w-full">
            <Input
              ref={innerRef}
              {...otherProps}
              className={cn("border-0 bg-transparent text-[32px]", className)}
            />
            {suggestionsComponent}
          </div>
        </div>
      </div>
    );
  }
);

StyledInput.displayName = "StyledInput";

export default StyledInput;
