"use client";

import React from "react";
import { Button, ButtonProps } from "@/components/ui/button";
import { useFormStatus } from "react-dom";

const FormSubmitButton: React.FC<ButtonProps> = ({ loading, ...props }) => {
  const { pending } = useFormStatus();

  return <Button type="submit" loading={loading || pending} {...props} />;
};

export default FormSubmitButton;
