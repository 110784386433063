"use client";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { DialogProps } from "@radix-ui/react-dialog";
import React, { useEffect } from "react";
import updateCookieUserId from "./formActions/updateCookieUserId";
import { FormSubmitButton } from "@/components/ui/formSubmitButton";
import { useFormState } from "react-dom";
import { redirect } from "next/navigation";

type Props = {
  cookieUserId?: string;
} & DialogProps;

const AdminModal: React.FC<Props> = ({ cookieUserId, ...props }) => {
  const [state, formAction] = useFormState(updateCookieUserId, undefined);
  const [open, setOpen] = React.useState(props.open);

  useEffect(() => {
    if (state?.success) {
      setOpen(false);
      redirect("/loyalty-dashboard");
    }
  }, [state]);

  useEffect(() => setOpen(props.open), [props.open]);

  return (
    <Dialog open={open} {...props}>
      <DialogContent className="py-8">
        <DialogHeader>
          <DialogTitle className="text-center text-3xl">
            Admin Panel
          </DialogTitle>
        </DialogHeader>
        <div className="mt-4 text-center text-xl text-primary">
          <div>Change User Id</div>
          {cookieUserId && (
            <div className="text-base text-gray-400">
              Curently Viewing: {cookieUserId}
            </div>
          )}
        </div>
        <form
          action={formAction}
          onReset={() => {
            formAction(new FormData());
          }}
          className="flex flex-col items-center justify-center"
        >
          <Input name="userId" placeholder="User Id" className="w-full" />
          <div className="flex gap-2">
            {cookieUserId && (
              <FormSubmitButton
                type="reset"
                variant="outline"
                size="sm"
                className="mt-4"
              >
                Reset
              </FormSubmitButton>
            )}
            <FormSubmitButton variant="outline" size="sm" className="mt-4">
              Submit
            </FormSubmitButton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AdminModal;
