"use client";

import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";
import React, { HTMLAttributes, PropsWithChildren } from "react";

const headerTransparentRoutes = ["/"];

type Props = PropsWithChildren<{
  className?: HTMLAttributes<HTMLDivElement>["className"];
}>;

const HeaderWrapper: React.FC<Props> = ({ children, className }) => {
  const pathname = usePathname();
  const isTransparent = headerTransparentRoutes.includes(pathname);

  return (
    <div
      className={cn(
        "h-[75px]",
        isTransparent ? "bg-opacity-50 backdrop-blur" : "bg-header-background",
        className
      )}
    >
      {children}
    </div>
  );
};

export default HeaderWrapper;
