"use client";

import React, { PropsWithChildren, useEffect } from "react";
import { AdminModal } from ".";

type Props = PropsWithChildren<{
  cookieUserId?: string;
  hasGondolaEmail?: boolean;
}>;

const AdminModalTrigger: React.FC<Props> = ({
  children,
  cookieUserId,
  hasGondolaEmail,
}) => {
  const [open, setOpen] = React.useState(false);
  const [domain, setDomain] = React.useState("");

  useEffect(() => setDomain(window?.location.host), []);

  if (
    (domain === "staging.gondola.ai" || domain === "localhost:3000") &&
    hasGondolaEmail
  ) {
    return (
      <>
        <AdminModal
          open={open}
          onOpenChange={() => setOpen(false)}
          cookieUserId={cookieUserId}
        />
        <div onClick={() => setOpen(true)}>{children}</div>
      </>
    );
  } else {
    return null;
  }
};

export default AdminModalTrigger;
