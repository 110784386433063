"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { PropsWithChildren } from "react";

type Props = {
  path: string;
  isActivePaths?: string[];
  className?: string;
};

const LinkItem: React.FC<PropsWithChildren<Props>> = ({
  path,
  isActivePaths,
  className,
  children,
}) => {
  const currentPath = usePathname();

  const isActive = isActivePaths
    ? isActivePaths.map((path) => currentPath.startsWith(path)).includes(true)
    : currentPath.startsWith(path);

  return (
    <Link
      href={path}
      className={cn(isActive ? "text-primary" : "text-neutral-400", className)}
    >
      {children}
    </Link>
  );
};

export default LinkItem;
