"use client";

import React, { PropsWithChildren } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

const Fallback: React.FC<FallbackProps> = () => {
  return (
    <div className="-mt-20 flex h-screen flex-col items-center justify-center gap-2">
      <div className="text-2xl text-primary">Oops! That&apos;s our fault.</div>
      <div className="text-primary">
        We&apos;re sorry for the inconvenience. Please try refreshing the page
        or contact support@gondola.ai if the problem persists.
      </div>
    </div>
  );
};

const ErrorBoundaryWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
