"use client";

import { SessionProvider, SessionProviderProps } from "next-auth/react";

export default function ClientProvider({
  children,
  session,
}: SessionProviderProps): React.ReactNode {
  return <SessionProvider session={session}>{children}</SessionProvider>;
}
